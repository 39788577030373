<template>
  <footer className="absolute max-w-xl left-0 right-0 bottom-0 m-auto p-6 text-s text-gray-400 text-center">
    <span>Made by <a className="hover:text-white" href="//igdaloff.com">Nathan Igdaloff</a> |
      <router-link to="/about" class="hover:text-white">About</router-link> | <a className="hover:text-white"
        href="https://g09lldp01b5.typeform.com/to/OoHX2k42">Feedback</a>
    </span>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>